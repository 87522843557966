<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Kota *
                </label>
                <Dropdown v-model="form.selected_city" :options="cities" :filter="true" optionLabel="name" placeholder="Pilih Kota" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Nama Kecamatan *
                </label>
                <InputText type="text" placeholder="Nama Kecamatan" v-model="form.name" :disabled="form.is_submit" />
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ['data', 'cities'],
    data() {
        return {
            form: {
                id: null,
                name: '',
                selected_city: '',
                city_id: '',
                city_name: '',
                is_submit: false,
            },
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.city_name = this.form.selected_city ? this.form.selected_city['name'] : ''
                this.form.city_id = this.form.selected_city ? this.form.selected_city['id'] : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
            this.form.selected_city = this.cities.find(obj => obj.id == this.form.city_id);
        }
    },
    methods: {},
}
</script>

<style lang="scss" scoped></style>
